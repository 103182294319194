import * as React from 'react'
import { Link } from 'gatsby'
import Img from './Img'

import { ReactComponent as Logo } from '../assets/logo.svg'

import { ImageSharp } from '../graphqlTypes'

const Header = ({ siteTitle, image }: { siteTitle: string; image: ImageSharp }) => (
  <>
    <header>
      <Img className="background" alt="" sizes={image.sizes} />
      <Link to="/" className="logo-wrapper" style={{ color: 'white', textDecoration: 'none' }}>
        <Logo width="100%" height="100%" alt={siteTitle} role="img" aria-label={siteTitle} />
      </Link>
    </header>
  </>
)
export default Header
