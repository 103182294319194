import * as React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

export const Footer = () => (
  <footer>
    <p className="tagline">
      <span>Olofsfors urmakeri</span> - <span>Ge din klocka lite kärlek</span>
    </p>
    <div className="wrapper" id="contact">
      <address>
        <span>Olofsfors urmakeri och hantverk</span>
        <span>Olofsfors 105 D</span>
        <span>914 91 Nordmaling</span>
      </address>

      <div className="contact">
        <div>
          Telefon:
          <a href="tel:+46702764663">0702 76 46 63</a>
        </div>
        <div>
          E-post:
          <a href="mailto:olofsforsur@gmail.com">olofsforsur@gmail.com</a>
        </div>
      </div>

      <div className="social">
        <a href="https://facebook.com/olofsforsur" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebookSquare} size="3x" className="vamid" /> Besök mig på
          Facebook!
        </a>
      </div>
    </div>
    <a href="https://kada.se" className="credit" rel="noreferrer noopener">
      made by Kåda
    </a>
  </footer>
)
export default Footer
