import * as React from 'react'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'

import 'typeface-spectral-sc'

import './style.scss'
import { Site, ImageSharp } from '../graphqlTypes'
import Header from '../components/header'
import Footer from '../components/footer'

import * as JsonLd from '../ld.json'

interface DefaultLayoutProps {
  data: {
    site: Site
    headerImage: ImageSharp
  }
  location: {
    pathname: string
  }
  children: any
}

export const Layout: React.SFC<DefaultLayoutProps> = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
          headerImage: imageSharp(fluid: { originalName: { regex: "/header/" } }) {
            sizes(maxWidth: 2560, quality: 90) {
              ...GatsbyImageSharpSizes_withWebp
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content: data.site.siteMetadata.description,
              },
            ]}
          >
            <html lang="sv" />
            <script type="application/ld+json">{JSON.stringify(JsonLd)}</script>
          </Helmet>
          <Header siteTitle={data.site.siteMetadata.title} image={data.headerImage} />
          <nav id="_">
            <Link to="/#_" activeClassName="active">
              Olofsfors urmakeri
            </Link>
            <Link to="/museum#_" activeClassName="active">
              Museum
            </Link>
          </nav>
          <main>{children}</main>
          <Footer />
        </>
      )}
    />
  )
}
